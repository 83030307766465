// Clear Sans
@font-face {
  font-family: "Clear Sans";
  src: local("Clear Sans Regular"),
  url("./clear-sans/ClearSans-Regular.woff") format("woff"),
  url("./clear-sans/ClearSans-Regular.eot") format("eot"),
  url("./clear-sans/ClearSans-Regular.ttf") format("ttf"),
  url("./clear-sans/ClearSans-Regular.svg") format("svg"),
  url("./clear-sans/ClearSans-Regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Clear Sans Medium";
  src: local("Clear Sans Medium"),
    url("./clear-sans/ClearSans-Medium.woff") format("woff"),
    url("./clear-sans/ClearSans-Medium.eot") format("eot"),
    url("./clear-sans/ClearSans-Medium.ttf") format("ttf"),
    url("./clear-sans/ClearSans-Medium.svg") format("svg"),
    url("./clear-sans/ClearSans-Medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Clear Sans Bold";
  src: local("Clear Sans Bold"),
  url("./clear-sans/ClearSans-Bold.woff") format("woff"),
  url("./clear-sans/ClearSans-Bold.eot") format("eot"),
  url("./clear-sans/ClearSans-Bold.ttf") format("ttf"),
  url("./clear-sans/ClearSans-Bold.svg") format("svg"),
  url("./clear-sans/ClearSans-Bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
