.privacy-message {
  border-color: #d1d3d4;
  background-color: #ffeed7;
  padding: 2rem 2rem 1rem 2rem;
  border-top: 0;
  color: #555;
  a {
    color: #0b3382;
  }
}
