@import "fonts.enisa.less";

//*// Body reset
html {
  font-size: unset;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@plone-font-family-sans-serif: "Clear Sans";
@plone-font-family-base: @plone-font-family-sans-serif;

@enisa-color-blue: #004087;
@enisa-color-lightblue: #0070c5;
@enisa-color-breadcrumbs: #a0a0ce;
@enisa-color-nav-blue: #0b3382;

@plone-btn-context-bg: @enisa-color-lightblue;
@plone-link-color: #3b58cf;
@plone-link-color-on-dark: @enisa-color-lightblue;
@plone-link-color-on-grey: @plone-link-color;
@plone-sitenav-bg: #fff;
@plone-body-bg: #fff;
@plone-footer-bg: #f2f2f2;

body {
  font-family: @plone-font-family-base;
}

svg {
  width: 100%;
  height: auto;
}

.video-react,
.video-react-poster {
  background-color: transparent !important;
}

#blue-banner {
  @media only screen and (max-width: 768px) {
    background: #0457af !important;
  }
}

#content-header {
  // margin-bottom: 6em;

  @media only screen and (max-width: 768px) {
    margin-bottom: 0;
  }
}

.template-homepage {
  #content-header {
    margin-bottom: 0;
  }
}

#mainnavigation {
  button {
    border: none;
  }
}

#content {
  margin-bottom: 0;
}

.outer-wrapper {
  background: url(++theme++ecsm/img/wide-pattern-blue.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: 26px;
}

.top-siteactions {
  background: #f4f4f4;
  a {
    border-left: 1px solid #cbcbcb;
    &:last-child {
      border-right: 1px solid #cbcbcb;
    }
  }
}

.think-b4 {
  margin-left: 12.5em;
  width: 100%;
  @media (max-width: 768px) {
    margin-left: 10.5em;
  }
}

#portal-logo {
  float: none;
}

.plone-nav li {
  label {
    color: @plone-text-color;
    &:after {
      content: "\2304";
      left: 0;
      top: 0.9rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0;
      margin: 0;
      width: auto;
      height: auto;
    }
  }
  &.current a,
  &.selected a {
    color: @enisa-color-nav-blue;
    background: none;
    &:hover,
    &:focus {
      color: @enisa-color-nav-blue;
      background: none !important;
    }
  }
  a {
    color: @enisa-color-nav-blue;
    padding: 0.5em 0.5em;
    // &:hover,
    // &:focus {
    //   background: none !important;
    // }
  }
  &.has_subtree {
    &:focus,
    &:hover {
    }
    .dropdown {
      a {
        background: #0155ae;
        color: #fff;
        &:hover,
        &:focus {
          background: #333333 !important;
        }
      }
      li:first-child a {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
      li:last-child a {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
}

.plone-navbar {
  background-color: transparent;
  position: relative;
  min-height: unset;
  margin-bottom: 0;
  box-shadow: none;

  .container {
    width: auto;
  }
}

#portal-searchbox {
  display: none;
}

.plone-breadcrumb {
  background: transparent;

  ol > li + li {
    &:before {
      content: ">";
      border-left-color: transparent;
      left: -25px;
    }
    &:after {
      color: transparent;
    }
  }
}

#footer-site-actions {
  ul {
    display: flex;
    justify-content: space-around;
    flex-flow: row wrap;
  }
  .portletItem {
    padding: 0.3em;
  }
}

// #portal-footer-wrapper {
//   box-shadow: inset 0 .1rem .5rem -.2rem rgba(0,0,0,.25);
//   color: @plone-gray-dark;
//   a {
//     color: @plone-link-color;
//   }
// }
//
// #portal-footer .site-actions ul {
//   text-align: right;
//
//   li.portletItem {
//     padding: 0.3em 0.5em;
//
//     a {
//       &,
//       &:visited,
//       &:hover,
//       &:focus,
//       &:active {
//         color: @plone-text-color;
//       }
//     }
//   }
// }

/* TinyMCE templates */

// image-grid-2x2:
.image-grid-2x2 {
  padding: 0;
  margin: 0 0 @plone-grid-gutter-width 0;
  list-style: none;
  .make-row();

  > li {
    .make-xs-column(12);
    .make-sm-column(6);
    margin-top: @plone-grid-gutter-width;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

html,
body {
  overflow-x: hidden;
}

.break-width {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
}

/* EC twitter */

.homepage-twitter {
  text-align: center;
  clear: both;
}

.smk-tweets {
  width: 400px;
}

.smk.wt-smk {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  max-width: 100%;
  width: 100%;
  @media (max-width: 768px) {
    justify-content: center;
  }
}

.smk-more {
  width: 100%;
  display: flex;
  justify-content: center;
}

.footer-item {
  @media only screen and (min-width: 1024px) {
    border-top: 0 !important;
  }
}

.documentFirstHeading {
  font-size: 3rem;
  font-weight: 700;
  margin-top: 3rem;
}

.documentDescription {
  margin: 1.5rem auto 1.5rem auto;
  width: calc(100% * 4 / 6);
}
